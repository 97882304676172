.typelist input[type="radio"],
.typelist .MuiRadio-root,
.typelist .MuiListSubheader-root {
	padding-left: 0;
}
.typelist .MuiListSubheader-root {
  line-height: 20px;
}
.dashboard .MuiRadio-colorSecondary.Mui-checked:hover,
.typelist .MuiRadio-colorSecondary:hover {
	background: none;
}
