.step2__signin {
	display: flex;
}
/* --------------------- form --------------------------- */

.step2__form {
	display: flex;
	flex-basis: 450px;
	flex-flow: column;
	padding-right: 1.5rem;
	margin: 1rem 0.6rem 1rem 0;
}
.step2__passInputs {
	display: flex;
	justify-content: space-between;
}
.step2__passInputs input {
	width: 49%;
}
.step2__formName,
.step2__formPass,
.step2__formEmail,
.step2__formCountry {
	font-size: 0.8rem;
	margin: 0.5rem 0;
	font-weight: 600;
	color: rgb(123, 121, 121);
}

/* --------------------- seperator --------------------------- */
.step2__seperator {
	border-left: 1px solid lightgrey;
	position: relative;
	margin: 0 1rem;
}
.step2__or {
	position: absolute;
  font-size: .8rem;
  color: rgb(123, 121, 121);
	background: var(--white);
	font-weight: 700;
	top: 4px;
	left: -7px;
	padding-bottom: 0.5rem;
}
/* --------------------- social --------------------------- */
.step2__social {
	display: flex;
	flex-flow: column;
	padding: 1rem;
	flex-shrink: 4;
}
.step2__socialTitle {
	font-size: 1.1rem;
	text-align: center;
	font-weight: 700;
	margin: 1.1rem 0.3rem 1.5rem;
	color: var(--gray-dark);
}
.step2__socialBtns {
	display: flex;
	flex-flow: column;
}
.step2__socialBtns button {
	margin: 0.4rem 1rem;
}
/* --------------------- continue --------------------------- */

.step2__continue {
	display: flex;
	margin: 2rem 0;
}
.step2__checkbox {
	margin-top: .2rem;
	margin-left: 2rem;
}
.step2__agreement {
	margin-left: .7rem;
}