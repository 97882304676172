.signup {
  background: url('../../assets/images/demo/signup-background.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.signup__wrapper {
  box-shadow: 0 2px 10px lightgrey;
  border-radius: 5px;
  background: #fff;
  padding: 3rem 3.5rem;
  width: 800px;
}
.signup__wrapper, .signup__steps {
  display: flex;
}
.signup__steps {
  min-width: 67%;
  max-width: 1100px;
  margin: 3rem auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
}
 

