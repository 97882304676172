#root {
  display: flex;
  flex-flow: column;
  height: 100vh;
}
img {
  max-height: 100%;
  max-width: 100%;
}
.textFields > .MuiFormControl-root {
  width: 100%;
}
.textFields .MuiTextField-root:not(:first-of-type) {
  margin-top: 1rem;
}
.uploads label {
  display: flex;
}
.uploads label img {
  object-fit: contain;
  max-height: 100px;
}
.uploads .MuiButton-root {
  text-transform: unset;
}
.multiText textarea {
  max-height: 55px;
  overflow: scroll;
}
.upload-form input[type="text"] {
  width: 210px;
}
.upload-form .image-button {
  margin-top: 0.5rem;
  min-width: 60%;
}
.upload-form .submit-button {
  color: #fff;
  background: green;
  padding: 0.4rem 3rem;
  text-transform: none;
}
.upload-form .videoUpload-button {
  color: #fff;
  background: #2f33ee;
  text-transform: none;
  padding: 0.7rem 2rem;
  /* border: none; */
}

.dashboard .upload-form {
  text-align: center;
  box-shadow: none;
  height: 100%;
  position: relative;
}

.MuiSnackbar-root .MuiPaper-root {
  background: #2f33ee;
}

button:focus {
  outline: none;
}
a:hover {
  text-decoration: none;
}
